export type HotelByCitySearchType = 'city' | 'city-star' | 'city-type' | 'city-facility' | 'city-chain' | 'landmark';

export type HotelByCountrySearchType = 'country' | 'country-star' | 'country-type' | 'country-facility';

export type HotelByRegionSearchType = 'region' | 'region-star' | 'region-type' | 'region-facility';

export type HotelByDistrictSearchType = 'district' | 'district-star' | 'district-type' | 'district-facility';

export type HotelSearchType =
  | 'airport'
  | 'hotel'
  | 'geo'
  | 'country'
  | HotelByCountrySearchType
  | HotelByCitySearchType
  | HotelByRegionSearchType
  | HotelByDistrictSearchType;

export type HotelSearchParams = {
  id: string;
  type: HotelSearchType;
  checkInDate?: string;
  checkOutDate?: string;
  rooms: number;
  adult: number;
  childAges: number[];
  destName?: string;
};

export type HotelStaticPayload = {
  language: string;
  countryCode?: string;
  country?: string;
  city?: string;
  chain?: string;
  district?: string;
  facility?: string;
  star?: string;
  propertyType?: string;
  landmark?: string;
  region?: string;
};

export type HotelSuggestion = {
  id: string;
  type: HotelSearchType;
  name: string;
  country?: string;
  hotelCount?: number;
  region?: string;
  city?: string;
  district?: string;
};

export type HotelRecommendation = {
  id: string;
  price: number;
  currency: string;
  checkInDate: string;
  checkOutDate: string;
  name: string;
  enName: string;
  star: number;
  rating: number;
  photo: string;
  city: string;
  slug: string;
};

export type HotelRecommendationCity = { id: string; name: string; enName: string; country: string };

export type HotelAutocompleteResponse = HotelSuggestion[];

export type HotelResultRoomInfo = {
  blockId: string;
  roomId: string;
  roomName: string;
  roomCount: number;
  includeBreakfast: boolean;
  includeBreakfastCount: number;
  remainingRooms: number;
  isRefundable: boolean;
  maxOccupancy: number;
  includeFreeWifi: boolean;
};

export type HotelSummary = {
  id: string;
  name: string;
  enName: string;
  star: number;
  latitude: string;
  longitude: string;
  country: string;
  city: string;
  district: string;
  reviews: {
    rating: number;
    count: number;
  };
  photos: {
    caption: string;
    url: string;
  }[];
  currency: string;
  price: number;
  paymentMethods?: string[];
  pricePerNight: number;
  deal?: {
    name: string;
    type: string;
    currency: string;
    valueBeforeDiscount: number;
    valueBeforeDiscountPerNight: number;
  };
  rooms: HotelResultRoomInfo[];
  distance: number;

  // added after fetching hotels
  tripAdviorReviews?: {
    rating: number;
    count: number;
    img: string;
  };
  slug?: string;
};

export type HotelResultSort =
  | 'recommendation'
  | 'lowest_price'
  | 'highest_price'
  | 'distance'
  | 'smart_save'
  | 'top_reviewed';

export type HotelResultFilters = {
  stars: number[];
  facilities: number[];
  types: number[];
  minPrice?: number;
  maxPrice?: number;
  minPricePerNight?: number | null;
  maxPricePerNight?: number | null;
  name?: string;
};

export type HotelResultFilterConfig = {
  minPrice: number;
  minPricePerNight: number;
  maxPrice: number;
  maxPricePerNight: number;
  stars: {
    star: number;
    count: number;
  }[];
  facilities: {
    id: number;
    name: string;
    count: number;
  }[];
  types: {
    id: number;
    name: string;
    count: number;
  }[];
};

export type HotelResultResponse = {
  requestId: string;
  hotelsCount: number;
  hotels: HotelSummary[];
  filterList: HotelResultFilterConfig;
};

export type HotelTripAdvisorReview = {
  rating: number;
  ratingCount: {
    [rating: number]: number;
  };
  tripTypes: {
    type: string;
    value: number;
  }[];
  subratings: {
    type: string;
    value: number;
  };
  awards: {
    type: string;
    year: string;
    images: {
      tiny: string;
      small: string;
      large: string;
    };
    categories: string[];
  };
  ratingImg: string;
};

export type HotelPhoto = {
  caption: string;
  url: string;
};

export type HotelFacility = {
  name: string;
  hotelFacilityId: number;
  group: string;
  hotelFacilityGroupId: number;
};

export type HotelGeneralFacility = {
  name: string;
  facilityId: number;
  group: string;
  groupId: number;
};

export type HotelRoomFacility = {
  name: string;
  roomFacilityId: number;
  group: string;
  roomFacilityGroupId: number;
};

export type HotelFaq = {
  question: string;
  answer: string;
  variable: object | null;
};

export type Hotel = {
  id: string;
  name: string;
  enName: string;
  star: number;
  latitude: string;
  longitude: string;
  country: string;
  city: {
    id: string;
    name: string;
  };
  district: {
    id: string;
    name: string;
  };
  reviews: {
    rating: number;
    count: number;
  };
  photos: HotelPhoto[];
  type: string;
  overview: string;
  snippet?: any;
  remark: string;
  address: string;
  zipCode: string;
  facilities: HotelFacility[];
  highlightedFacilities: HotelFacility[];
  checkInFrom: string | null;
  checkInTo: string | null;
  checkOutFrom: string | null;
  checkOutTo: string | null;
  numRooms: number;
  spokenLanguages: {
    code: string;
    name: string;
  }[];
  policies: {
    type: string;
    name: string;
    content: string;
  }[];
  email: string;
  phone: string;
  fax: string;
  updatedAt: string;
  reviewTripadvisor?: HotelTripAdvisorReview & { count: number };
  faq: HotelFaq[];
  isClosed: boolean;
};

export type HotelRoomDeal = {
  name: string;
  type: string;
  currency: string;
  value: number;
};

export type HotelRoomIncrementalPriceDeal = {
  name: string;
  type: string;
  currency: string;
  valueBeforeDiscount: number;
};

export type HotelRoomCancellationInfo = {
  from: string;
  until: string;
  timezone: number;
  fee: number;
  currency: string;
};

export type HotelRoomSurcharge = {
  name: string;
  amount: number;
  type: 'mandatory' | 'excluded';
};

export type HotelRoomIncrementalPrice = {
  currency: string;
  price: number;
  basePrice: number;
  tax: number;
  surcharges: HotelRoomSurcharge[];
  deal: HotelRoomIncrementalPriceDeal | null;
};

export type HotelRoom = {
  blockId: string;
  roomId: string;
  includeBreakfast: boolean;
  includeBreakfastCount: number;
  includeLunch: boolean;
  includeDinner: boolean;
  includeFreeWifi: boolean;
  maxOccupancy: number;
  remainingRooms: number;
  isRefundable: boolean;
  refundableUntil: string;
  incrementalPrice: HotelRoomIncrementalPrice[];
  hotelCurrency: HotelRoomIncrementalPrice[];
  cancellationInfo: HotelRoomCancellationInfo[];
  cancellationPolicy: { [lang: string]: string };
  maxChildrenFreeAge: number;
  maxChildrenFree: number;
  childrenStayFree: boolean;
  paymentMethods: string[];
  benefits: HotelFacility[];
  deal: HotelRoomDeal | null;
  includedExtraBed: number;
  ccRequired: boolean;
  supplierId: string;
  supplier: string;
  price: number;
  currency: string;
  cache: number;
  name: string;
  description: string;
  roomSizeImperial: number;
  bedInfo: string;
  mainPhotos: HotelPhoto[];
  photos: HotelPhoto[];
  facilities: HotelRoomFacility[];
};

export type HotelPopular = {
  id: string;
  price: number;
  currency: string;
  checkInDate: string;
  checkOutDate: string;
  name: string;
  enName: string;
  star: number;
  rating: number;
  photo: string;
  city: string;
};

export type HotelPopularDestination = {
  id: string;
  type: string;
  name: string;
  count: number;
  nameEn: string;
  country: string;
};

export type HotelSearchHistory = Omit<HotelSearchParams, 'destName'> & {
  name: string;
  district: string;
  city: string;
  region: string;
};

export type HotelResultHotelInfo = {
  id: string;
  name: string;
  enName: string;
  star: number;
  latitude: string;
  longitude: string;
  country: string;
  city: string;
  district: string;
  reviews: {
    rating: number;
    count: number;
  };
  photos: {
    caption: string;
    url: string;
  }[];
  currency: string;
  price: number;
  paymentMethods?: string[];
  pricePerNight: number;
  deal?: {
    name: string;
    type: string;
    currency: string;
    valueBeforeDiscount: number;
    valueBeforeDiscountPerNight: number;
  };
  rooms: HotelResultRoomInfo[];
  distance: number;

  // added after fetching hotels
  tripAdviorReviews?: {
    rating: number;
    count: number;
    img: string;
  };
};

export type HotelDetailSearchParams = Omit<HotelSearchParams, 'type'> & {
  child: number;
  lang: string;
  country: string;
  currency: string;
  live: number;
};

export type HotelRoomSearchParams = Omit<HotelDetailSearchParams, 'live'>;

export type LiveCrawlStatus = {
  [searchId: string]: { done: boolean; progress: number; countJob: number; lastUpdate: number };
};

export type HotelSelectedRooms = {
  [blockId: string]: HotelRoom & { roomCount: number };
};

export type HotelPopularList = {
  id: string;
  name: string;
  slug: string;
};

export type HotelSharedLinkSearchParams = {
  id: string;
  slug: string;
  lang: string;
  checkInDate?: string;
  checkOutDate?: string;
  rooms: number;
  adult: number;
  childAges: number[];
};

export type HotelStaticBaseSectionParams = {
  id: string;
  name: string;
  slug: string;
};

export type HotelFaqStatic = {
  question: string;
  answer: string;
  variables: Record<string, string | number | { id?: string; name: string; slug: string }[]>;
  variablesAnswer?: Record<string, string>;
};

export type HotelStaticHotelParams = HotelStaticBaseSectionParams & {
  photo: string;
  district: string;
  city: string;
  star: number;
  reviews: {
    rating: number;
    count: number;
  };
  overview: string;
  facilities: {
    id: number;
    name: string;
  }[];
  distance: number;
};

export type HotelStaticMoreRecommendationParams = HotelStaticBaseSectionParams & {
  photo: string;
  district: string;
  city: string;
  star: number;
  reviews: {
    rating: number;
    count: number;
  };
};

export type HotelStaticBaseParams = {
  name: string;
  enName: string;
  hotelCount: number;
  faq: HotelFaqStatic[];
  hotels: HotelStaticHotelParams[];
  footerHotels: HotelStaticBaseSectionParams[];
  moreHotels: HotelStaticMoreRecommendationParams[];
};

export type HotelAreaSectionHotels = {
  id: string;
  name: string;
  slug: string | null;
  location: string;
  star: number;
  distance: number;
  photo: string;
  reviews: {
    rating: number;
    count: number;
  };
};

export type HotelAreaSection = {
  id: string;
  name: string;
  enName: string;
  hotels: HotelAreaSectionHotels[];
};

export type HotelPropertyTypeSection = {
  name: string;
  enName: string;
};

export type HotelFacilitiesSection = {
  name: string;
  enName: string;
};

export type HotelDistrictSection = {
  id: number;
  name: string;
  enName: string;
  propertyCount: number;
};

export type HotelLandmarkSection = {
  id: number;
  name: string;
  enName: string;
  propertyCount: number;
  countryCode: string;
};

export type HotelCitySection = {
  id: number;
  name: string;
  enName: string;
  propertyCount: number;
  countryCode: string;
};

export type HotelNearbySection = {
  name: string;
  enName: string;
  propertyCount: number;
  countryCode: string;
};

export type HotelNearbySectionWithId = HotelNearbySection & {
  id: string | number;
};

export type HotelRegionSection = {
  id: number;
  name: string;
  enName: string;
  propertyCount: number;
  countryCode: string;
};

export type HotelStaticSectionParams = {
  code: string;
  name: string;
  enName: string;
  propertyCount: number;
};

export type HotelStaticHotelSimpleParams = HotelStaticBaseSectionParams & {
  photo: string;
  district: string;
  city: string;
};

export type HotelByCity = HotelStaticBaseParams & {
  id: string;
  countryName: string;
  countryCode: string;
  country: {
    code: string;
    name: string;
    enName: string;
  };
  region?: {
    name: string;
    enName: string;
  };
  hotelByArea: HotelAreaSection[];
  propertyTypes: HotelPropertyTypeSection[];
  facilities: HotelFacilitiesSection[];
  districts: HotelDistrictSection[];
  landmarks: HotelLandmarkSection[];
  popularAirport: {
    code: string;
    name: string;
    nameEn: string;
  };
  nearby: HotelNearbySection[];
  airports: HotelStaticSectionParams[];
  budgetHotels: HotelStaticHotelSimpleParams[];
  hotelStars: {
    star: number;
    count: number;
  }[];
};

export type HotelByCityStar = Omit<HotelByCity, 'propertyTypes' | 'facilities' | 'airports' | 'budgetHotels'> & {
  star: number;
  hotelsInCityCenter: Omit<HotelStaticMoreRecommendationParams, 'reviews'>[];
  hotelsNearAirport: Omit<HotelStaticMoreRecommendationParams, 'reviews'>[];
  popularHotels: HotelStaticHotelSimpleParams[];
};

export type HotelByCityType = Omit<HotelByCity, 'facilities' | 'airports' | 'budgetHotels'> & {
  propertyType: string;
};

export type HotelByCityFacility = Omit<HotelByCity, 'propertyTypes' | 'airports' | 'budgetHotels' | 'hotelStars'> & {
  facility: string;
};

export type HotelByCityChain = Omit<HotelByCity, 'propertyTypes' | 'facilities' | 'airports' | 'budgetHotels'> & {
  chain: string;
  landmarks: HotelLandmarkSection[];
  nearby: HotelNearbySection[];
  district: {
    name: string;
    enName: string;
  };
  chains: {
    name: string;
    enName: string;
    propertyCount: number;
    photo: string;
  }[];
  popularAirport: {
    code: string;
    name: string;
    nameEn: string;
  };
};

export type HotelByLandmark = HotelStaticBaseParams & {
  id: string;
  countryName: string;
  countryCode: string;
  country: {
    code: string;
    name: string;
    enName: string;
  };
  region?: {
    name: string;
    enName: string;
  };
  city: {
    id: number;
    name: string;
    enName: string;
  };
  hotelByArea: HotelAreaSection[];
  nearby: HotelNearbySectionWithId[];
  districts: HotelDistrictSection[];
};

export type HotelByCountry = HotelStaticBaseParams & {
  code: string;
  totalCity: number;
  popularCity: string;
  popularCities: HotelStaticSectionParams[];
  recommendedCountries: HotelStaticSectionParams[];
  airports: Array<HotelStaticSectionParams & { city: string }>;
  hotelByArea: HotelAreaSection[];
  facilities: HotelFacilitiesSection[];
  propertyTypes: HotelPropertyTypeSection[];
  landmarks: HotelLandmarkSection[];
  regions: HotelRegionSection[];
  cities: HotelCitySection[];
  nearby: HotelNearbySection[];
  hotelStars: {
    star: number;
    count: number;
  }[];
  budgetHotels?: HotelStaticHotelSimpleParams[];
};

export type HotelByCountryStar = HotelByCountry & {
  star: number;
};

export type HotelByCountryType = Omit<HotelByCountry, 'facilities' | 'airports' | 'budgetHotels'> & {
  propertyType: string;
};

export type HotelByCountryFacility = Omit<
  HotelByCountry,
  'popularAirport' | 'propertyTypes' | 'airports' | 'budgetHotels' | 'hotelStars'
> & {
  facility: string;
};

export type HotelByRegion = HotelStaticBaseParams & {
  id: string;
  countryName?: string;
  countryCode?: string;
  country: {
    code: string;
    name: string;
    enName: string;
  };
  popularAirport: {
    code: string;
    name: string;
    nameEn: string;
  };
  airports: HotelStaticSectionParams[];
  hotelByArea: HotelAreaSection[];
  propertyTypes: HotelPropertyTypeSection[];
  facilities: HotelFacilitiesSection[];
  districts: HotelDistrictSection[];
  nearby: HotelNearbySectionWithId[];
  cities: HotelCitySection[];
  landmarks: HotelLandmarkSection[];
  hotelStars: {
    star: number;
    count: number;
  }[];
  budgetHotels?: HotelStaticHotelSimpleParams[];
};

export type HotelByRegionType = Omit<HotelByRegion, 'facilities' | 'airports' | 'budgetHotels'> & {
  propertyType: string;
};

export type HotelByRegionFacility = Omit<
  HotelByRegion,
  'popularAirport' | 'propertyTypes' | 'airports' | 'budgetHotels' | 'hotelStars'
> & {
  facility: string;
};

export type HotelByRegionStar = Omit<HotelByRegion, 'propertyTypes' | 'facilities' | 'airports' | 'budgetHotels'> & {
  star: number;
};

export type HotelByDistrict = HotelStaticBaseParams & {
  id: string;
  countryName: string;
  countryCode: string;
  country: {
    code: string;
    name: string;
    enName: string;
  };
  region?: {
    name: string;
    enName: string;
  };
  city?: {
    name: string;
    enName: string;
  };
  hotelByArea: HotelAreaSection[];
  propertyTypes: HotelPropertyTypeSection[];
  facilities: HotelFacilitiesSection[];
  popularAirport: {
    code: string;
    name: string;
    nameEn: string;
  };
  airports: HotelStaticSectionParams[];
  nearby: HotelNearbySectionWithId[];
  cities: HotelCitySection[];
  landmarks: HotelLandmarkSection[];
  hotelStars: {
    star: number;
    count: number;
  }[];
  budgetHotels?: HotelStaticHotelSimpleParams[];
};

export type HotelByDistrictType = Omit<HotelByDistrict, 'facilities' | 'airports' | 'budgetHotels'> & {
  propertyType: string;
};

export type HotelByDistrictFacility = Omit<
  HotelByDistrict,
  'propertyTypes' | 'airports' | 'budgetHotels' | 'hotelStars'
> & {
  facility: string;
};

export type HotelByDistrictStar = Omit<HotelByDistrict, 'propertyTypes' | 'airports' | 'budgetHotels'> & {
  star: number;
};

export type HotelByAirport = HotelStaticBaseParams & {
  code: string;
  cityName: string;
  countryCode: string;
  countryName: string;
  country: {
    code: string;
    name: string;
    enName: string;
  };
  region?: {
    name: string;
    enName: string;
  };
  city?: {
    name: string;
    enName: string;
  };
  popularCities: string[];
  popularAirlines: string[];
  hotelsWithShuttle: HotelStaticHotelSimpleParams[];
};

export type HotelByCityStaticType =
  | HotelByCity
  | HotelByCityStar
  | HotelByCityType
  | HotelByCityFacility
  | HotelByCityChain
  | HotelByLandmark;

export type HotelByCountryStaticType =
  | HotelByCountry
  | HotelByCountryStar
  | HotelByCountryType
  | HotelByCountryFacility;

export type HotelByRegionStaticType = HotelByRegion | HotelByRegionType | HotelByRegionStar | HotelByRegionFacility;

export type HotelByDistrictStaticType =
  | HotelByDistrict
  | HotelByDistrictType
  | HotelByDistrictFacility
  | HotelByDistrictStar;

export type HotelStaticType =
  | HotelByCityStaticType
  | HotelByCountry
  | HotelByAirport
  | HotelByCityStaticType
  | HotelByCountryStaticType
  | HotelByRegionStaticType
  | HotelByDistrictStaticType;

export enum HotelFetchError {
  NO_RESULT_FILTER = 'no_result_filter',
  NO_RESULT = 'no_result',
  FETCH_ERROR = 'fetch_error',
}

export enum HotelSort {
  RECOMMENDED = 'recommendation',
  HIGHEST_PRICE = 'lowest_price',
  LOWEST_PRICE = 'highest_price',
  TOP_REVIEW = 'top_reviewed',
  DISTANCE = 'distance',
  SMART_SAVING = 'smart_save',
}

export enum HotelViewMode {
  LIST_VIEW = 'list',
  MAP_VIEW = 'map',
}

export type HotelSpecialRequest = {
  selectedChecks: string[];
  message: string;
  selectedArrivalStr: string;
  selectedArrival: number;
};

export type HotelPath = {
  id: string;
  name: string;
  slug?: string;
  enName?: string;
};

export type HotelDetailQuery =
  | {
      ci: string;
      co: string;
      ro: string | number;
      ad: string | number;
      ca?: string | number;
      st?: string | number;
      so?: string | number;
      ss?: string | number;
      mode?: string;
      fa?: string | number;
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      utm_content?: string;
      id: string;
      d: string;
      t: string;
    }
  | {};

export type HotelSearchQuery =
  | {
      id: string;
      d: string;
      t: string;
      ci: string;
      co: string;
      ro: string | number;
      ad: string | number;
      ca?: string | number;
    }
  | {};
